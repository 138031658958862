<template>
  <section class="news-wrap" v-if="Object.keys(dataInfo).length !== 0">
    <div class="news-info">
      <h1>{{ dataInfo.title }}</h1>
      <div class="p">
        <!-- 来源： -->
        <span class="source" v-if="dataInfo.sourceName"
          >{{ $t("LB_Doc_Source") }}：<a
            :href="
              dataInfo.sourceUrl.includes('?')
                ? `${dataInfo.sourceUrl}&ddtab=true`
                : `${dataInfo.sourceUrl}?ddtab=true`
            "
            :target="newWindow == 1 ? '_blank' : '_self'"
            v-if="dataInfo.sourceUrl"
            >{{ dataInfo.sourceName }}</a
          ><template v-else>{{ dataInfo.sourceName }}</template></span
        >
        <span class="date" v-if="dataInfo.publishTime"
          >{{ $t("news.publish_time") }}：{{
            dateFormat(dataInfo.publishTime)
          }}</span
        >
        <!-- 发布时间： -->
        <span class="item">
          <EyeOutlined style="font-size: 16px; margin-right: 4px" />
          {{ dataInfo.viewCount }}
        </span>
        <template v-if="dataInfo.isComment == 1 || commentParams.Total > 0">
          <span class="item">
            <MessageOutlined style="font-size: 16px; margin-right: 4px" />
            {{ commentParams.Total }}
          </span>
        </template>
        <template v-if="dataInfo.isLike == 1 || dataInfo.likes > 0">
          <span class="item">
            <LikeOutlined style="font-size: 16px; margin-right: 4px" />
            {{ dataInfo.likes }}
          </span>
        </template>
      </div>
      <div class="lables">
        <!-- <span v-for="(item, index) in dataInfo.labels" :key="index">
          {{ item }}
        </span> -->
      </div>
    </div>
    <div style="height: 30px; background: #fff" v-if="dataInfo.subtitle"></div>
    <div class="subtitle" v-if="dataInfo.subtitle">{{ dataInfo.subtitle }}</div>
    <div class="news-cont">
      <div
        class="w-e-text"
        v-viewer.static="{ modal: true, title: false }"
        v-html="dataInfo.content"
      ></div>
      <div class="statement">
        <p v-if="dataInfo.bottomDescription == 1 && companyInfo.disclaimer">
          {{ $t("news.disclaimer") }}: {{ companyInfo.disclaimer }}
          <!-- 免责声明 -->
        </p>
        <p v-else-if="dataInfo.bottomDescription == 2 && companyInfo.copyright">
          {{ $t("news.copyright_notice") }}: {{ companyInfo.copyright }}
          <!-- 版权声明 -->
        </p>
      </div>
      <div
        style="
          text-align: center;
          margin-top: 48px;
          z-index: 12;
          position: relative;
        "
        v-if="dataInfo.isFeedback == 1"
      >
        <a-button
          type="primary"
          size="large"
          shape="round"
          @click="finishRead"
          style="background: #266fff; border-color: #266fff; height: 52px"
          v-if="!dataInfo.isRead"
          >{{ $t("news.finish_reading") }}</a-button
        >
        <!-- 完成阅读 -->
        <a-button
          type="primary"
          size="large"
          shape="round"
          disabled
          style="height: 52px"
          v-else
          >{{ $t("news.have_read", 2) }}</a-button
        >
        <!-- 已阅读 -->
      </div>
    </div>
    <div
      class="news-comment"
      id="news-comment"
      v-show="dataInfo.isComment == 1 || commentParams.Total > 0"
    >
      <my-comment
        v-if="commentParams.resourceId > 0"
        :disabled="dataInfo.isComment != 1"
        :CommentParams="commentParams"
      ></my-comment>
    </div>
    <div style="position: absolute; top: 500px; left: 0">
      <a-affix :offset-top="250" style="text-align: right">
        <div style="transform: translateX(1202px)">
          <div
            v-if="
              dataInfo.isLike == 1 ||
              dataInfo.isComment == 1 ||
              commentParams.Total > 0
            "
            style="
              margin-bottom: 11px;
              border: 1px solid #e6e6e6;
              border-radius: 4px;
              padding: 2px;
              background: #fff;
            "
          >
            <a class="float" @click="clickLike()" v-if="dataInfo.isLike == 1">
              <LikeOutlined v-if="!dataInfo.isLikes" />
              <LikeFilled v-else style="color: #ff9900" />
            </a>
            <a
              class="float"
              href="#news-comment"
              v-if="dataInfo.isComment == 1 || commentParams.Total > 0"
            >
              <MessageOutlined />
            </a>
          </div>
          <div
            style="
              border: 1px solid #e6e6e6;
              border-radius: 4px;
              padding: 2px;
              background: #fff;
            "
          >
            <a class="float" @click="backTop()">
              <UpOutlined />
            </a>
          </div>
        </div>
      </a-affix>
    </div>
  </section>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, computed } from "@vue/reactivity";
import { useStore } from "vuex";
import { newsdetail, newslike, newsread } from "@/api/news";
import { useRoute, useRouter } from "vue-router";
import myComment from "@/components/my-comment";
import { dateFormat } from "@/utils/tools";
export default {
  components: {
    "my-comment": myComment,
  },
  setup() {
    const { t: $t } = useI18n();
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const companyInfo = computed(() => store.getters.companyInfo);
    const state = reactive({
      id: parseInt(route.query.id || 0),
      dataInfo: {},
      commentParams: {
        page: 1,
        pageSize: 10,
        resourceId: parseInt(route.query.id || 0),
        type: 3, //0知识 1课程 2任务 3新闻
        Total: 0,
      },
    });

    newsdetail({ id: state.id }).then((res) => {
      if (res.ret == 0) {
        state.dataInfo = res.data;
        if (state.dataInfo.labels) {
          state.dataInfo.labels = state.dataInfo.labels.split(",");
        }
      } else if (res.ret == 1 && res.msg == $t("XB_Parameter_Error")) {
        // 参数错误
        router.replace({
          path: `/error`,
          query: {
            title: $t("news.no_exist"),
            // 抱歉，您访问的新闻不存在。
            path: "/news",
            name: $t("news.news_center"),
            // 新闻中心
          },
        });
      } else {
        router.replace({
          path: `/error`,
          query: {
            title: $t("sorry_you_visited", [res.msg]),
            // 抱歉，您访问的
            path: "/news",
            name: $t("news.news_center"),
            // 新闻中心
          },
        });
      }
    });

    const clickLike = () => {
      newslike({ id: state.id }).then((res) => {
        if (res.ret == 0) {
          state.dataInfo.isLikes = res.data;
          if (res.data) {
            state.dataInfo.likes = state.dataInfo.likes + 1;
          } else {
            state.dataInfo.likes = state.dataInfo.likes - 1;
          }
        }
      });
    };

    const backTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };

    const finishRead = () => {
      if (!state.dataInfo.isRead) {
        newsread({ id: state.id }).then((res) => {
          if (res.ret == 0) {
            state.dataInfo.isRead = true;
          }
        });
      }
    };

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      clickLike,
      backTop,
      finishRead,
      dateFormat,
      companyInfo,
    };
  },
};
</script>

<style lang="less" scoped>
.news-wrap {
  position: relative;
  .mixinWrap();
  padding: 32px 0 40px;
  .news-info {
    background: #fff;
    padding: 30px 30px 0 30px;
    h1 {
      font-size: 24px;
      color: #333333;
      text-align: center;
    }
    .p {
      font-size: 14px;
      line-height: 24px;
      color: #bbbbbb;
      margin-bottom: 10px;
      .mixinFlex(center; center);
      .source {
        margin-right: 24px;
        a {
          color: var(--theme);
        }
      }
      .date {
        margin-right: 24px;
      }
      .item {
        margin-right: 24px;
        .mixinFlex(center; center);
        display: inline-flex;
        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
    .lables {
      .mixinFlex(center; center);
      span {
        height: 24px;
        font-size: 13px;
        line-height: 24px;
        padding: 0 10px;
        margin-right: 10px;
        border-radius: 10px;
        color: rgb(104, 128, 187);
        background-color: rgb(237, 242, 255);
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  .subtitle {
    padding: 10px 10px;
    background: #f8f8f8;
    color: #666666;
    font-size: 16px;
    letter-spacing: 1px;
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 56px;
    border-right-width: 56px;
    border-style: solid;
    border-color: #fff;
  }
  .news-cont {
    background: #fff;
    padding: 36px 150px;
    font-size: 18px;
    line-height: 29px;
    margin-bottom: 20px;
    .statement {
      font-size: 14px;
      color: #bbb;
    }
  }
  .news-comment {
    background: #fff;
    border-radius: 4px;
    padding: 30px;
  }
  .float {
    width: 66px;
    height: 66px;
    display: block;
    font-size: 26px;
    font-weight: 400;
    color: #282828;
    background: #fff;
    text-align: center;
    .anticon {
      padding: 21px 8px;
      border-bottom: 1px solid #f5f5f5;
    }
  }
  .float:nth-last-child(1) .anticon {
    border-bottom: none;
  }
}
</style>
<style scoped>
.w-e-text {
  padding: 0 10px;
  overflow-y: auto;
}
.w-e-text >>> i {
  font-style: italic;
}
.w-e-text >>> p,
.w-e-text >>> h1,
.w-e-text >>> h2,
.w-e-text >>> h3,
.w-e-text >>> h4,
.w-e-text >>> h5,
.w-e-text >>> table,
.w-e-text >>> pre {
  margin: 10px 0;
  line-height: 1.5;
}
.w-e-text >>> ul,
.w-e-text >>> ol {
  margin: 10px 0 10px 20px;
  list-style: initial;
}
.w-e-text >>> blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}
.w-e-text >>> code {
  display: inline-block;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 3px 5px;
  margin: 0 3px;
}
.w-e-text >>> pre code {
  display: block;
}
.w-e-text >>> table {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.w-e-text >>> table td,
.w-e-text >>> table th {
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  padding: 3px 5px;
  min-height: 30px;
  height: 30px;
}
.w-e-text >>> table th {
  border-bottom: 2px solid #ccc;
  text-align: center;
  background-color: #f1f1f1;
}
.w-e-text:focus {
  outline: none;
}
.w-e-text >>> img {
  max-width: 100%;
}
.w-e-text >>> .w-e-todo {
  margin: 0 0 0 20px;
}
.w-e-text >>> .w-e-todo li {
  list-style: none;
  font-size: 1em;
}
.w-e-text >>> .w-e-todo li span:nth-child(1) {
  position: relative;
  left: -18px;
}
.w-e-text >>> .w-e-todo li span:nth-child(1) input {
  position: absolute;
  margin-right: 3px;
}
.w-e-text >>> .w-e-todo li span:nth-child(1) input[type="checkbox"] {
  top: 50%;
  margin-top: -6px;
}
.w-e-text >>> .content .section span {
  font-weight: 800;
}
</style>
